import React, { FC } from 'react'
import Image from 'next/image'
import { Box, VStack, Text } from '@chakra-ui/react'
import { H2Title, ListWithIcons, TextLink } from 'src/uikit'
import { ArrowForwardIcon } from '@chakra-ui/icons'

interface Props {
  image: string
  itemsTitle: string
  items: Array<string>
  descriptionTitle: string
  description: string
  actionText: string
  actionTitle: string
}

const ServicesSectionMobile: FC<Props> = ({
  image,
  itemsTitle,
  items,
  description,
  descriptionTitle,
  actionText,
  actionTitle,
}) => (
  <VStack spacing={-130} align="stretch">
    <Box bg="baseNeutral.800" p="l" pb="8rem" pos="relative">
      <H2Title mr="5" mb="7" fontSize="1.5rem" lineHeight="1.938rem">{descriptionTitle}</H2Title>
      <Text
        fontFamily="Montserrat-Medium"
        fontSize="1rem"
        lineHeight="1.425rem"
        textAlign="left"
        color="baseWhite"
        mt=".75rem"
      >
        {description}
      </Text>
    </Box>
    <Box p="l" zIndex="2" position="relative" h="230px">
      <Box position="relative" h="100%" w="100%">
        <Image
          src={image}
          layout="fill"
          loading="lazy"
          decoding="async"
          alt={itemsTitle}
          className="rounded"
        />
      </Box>
    </Box>
    <Box bg="baseWhite" p="l" pt="8rem" textAlign="left">
      <H2Title color="baseDark" fontSize="1.125rem" lineHeight="1.463rem">{itemsTitle}</H2Title>
      <ListWithIcons items={items} color="baseDark" fontSize="0.875rem" />
      <TextLink href="/noleggio-a-lungo-termine/vantaggi" title={actionTitle} color="baseDark">
        {actionText} <ArrowForwardIcon />
      </TextLink>
    </Box>
  </VStack>
)

export default ServicesSectionMobile
